import React, { FunctionComponent } from "react"

import "./Hero.scss"
import { isMobile } from "react-device-detect"
import classNames from "classnames"
import { CssGrid } from "./CssGrid"

export const Hero: FunctionComponent<{}> = ({ children }) => (
  <CssGrid className={classNames("hero", { mobile: isMobile })}>
    {children}
  </CssGrid>
)

export default Hero
