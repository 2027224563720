import React, { FunctionComponent } from "react"
import Grid from "react-css-grid"

import "./CssGrid.scss"

export const CssGrid: FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => (
  <Grid className={className} gap={0} width="19rem">
    {children}
  </Grid>
)

export default CssGrid
