import React, { FunctionComponent, useState, useEffect } from "react"
import ReactDOM from "react-dom"

import Modal from "simple-react-modal"

import "./SimpleModal.scss"

export const SimpleModal: FunctionComponent<{
  open: boolean
  containerStyle?: object
  buttonStyle?: object
  onClose: () => void
}> = ({
  children,
  open,
  onClose,
  containerStyle = {
    background: "none",
    maxWidth: "56rem",
    width: "auto",
  },
  buttonStyle = {
    color: "var(--color-white)",
  },
}) => {
  const holder =
    typeof document !== `undefined`
      ? document.querySelector("#modal-root")
      : null
  const body =
    typeof document !== `undefined` ? document.querySelector("body") : null

  const [isOpen, setIsOpen] = useState<boolean>(open)
  const handleClose = () => {
    body && body.classList.toggle("modal-open")
    onClose()
  }

  useEffect(() => {
    setIsOpen(open)
    body && open && body.classList.toggle("modal-open")
  }, [open])

  if (holder)
    return ReactDOM.createPortal(
      <Modal
        show={isOpen}
        onClose={handleClose}
        transitionSpeed={200}
        style={{ transition: "opacity 200ms ease-in" }}
        containerStyle={containerStyle}
      >
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          style={buttonStyle}
        >
          <span aria-hidden="true">×</span>
        </button>
        {children}
      </Modal>,
      holder
    )
  else return null
}

export default SimpleModal
