import React, { FunctionComponent, useState, useEffect } from "react"

import classNames from "classnames"
import { isMobile } from "react-device-detect"
import { IContent } from "../types"

import "./Content.scss"
import { Button } from "./Button"
import { ContentImage } from "./ContentImage"

export const Content: FunctionComponent<IContent> = ({
  background = "light",
  title = "",
  titleSmaller = "",
  alignItems = "center",
  image,
  backgroundImage,
  transparent,
  pcarousel,
  white,
  fillBlockWithImage,
  link,
  paragraphs,
  listItems,
  callouts,
  fillBackgroundWithImage,
  pretitle,
  hideMobile,
  gallery,
  table,
  children,
}) => {
  const getHtml = (content: string) => ({
    __html: content,
  })
  const [imageSrc, setImageSrc] = useState(backgroundImage)
  useEffect(() => {
    backgroundImage &&
      backgroundImage.indexOf("//") === -1 &&
      import(`../images/content/${backgroundImage}`).then(module => {
        setImageSrc(module.default)
      })
  }, [backgroundImage])
  return !hideMobile || !isMobile ? (
    <div
      style={{
        backgroundImage: imageSrc ? `url(${imageSrc})` : "",
      }}
      className={classNames("content", {
        [background]: background,
        filled: fillBlockWithImage,
        cover: fillBackgroundWithImage,
        mobile: isMobile,
        transparent,
        pcarousel,
        white,
        "has-background": backgroundImage,
        "has-gallery": gallery,
      })}
    >
      <div
        className="column"
        style={{
          alignItems,
          textAlign: alignItems === "center" ? "center" : "right",
        }}
      >
        {pretitle && <p dangerouslySetInnerHTML={getHtml(pretitle)} />}
        {title && <h2 dangerouslySetInnerHTML={getHtml(title)} />}
        {titleSmaller && <h3 dangerouslySetInnerHTML={getHtml(titleSmaller)} />}
        {image && <ContentImage image={image} link={link} title={title} />}
        {paragraphs &&
          paragraphs.map((item, index) => (
            <p key={index} dangerouslySetInnerHTML={getHtml(item)} />
          ))}
        {listItems && (
          <ul className="content-list">
            {listItems.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={getHtml(item)} />
            ))}
          </ul>
        )}
        {children}
        {table && (
          <table>
            {table.header && (
              <thead>
                <tr>
                  {table.header.map((item, index) => (
                    <th
                      style={{
                        textAlign: item.textAlign,
                        textTransform: item.textTransform,
                        verticalAlign: item.verticalAlign,
                        background: item.backgroundColor,
                      }}
                      key={index}
                    >
                      {item.content}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody className="content-list">
              {table.rows.map((row, index) => (
                <tr key={index}>
                  {row.cells.map((cell, index) => (
                    <td
                      style={{
                        textAlign: cell.textAlign,
                        textTransform: cell.textTransform,
                        verticalAlign: cell.verticalAlign,
                        background: cell.backgroundColor,
                        width: `${100 / row.cells.length}%`,
                      }}
                      key={index}
                    >
                      {cell.content}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {callouts && (
          <div
            className="callouts"
            style={{
              justifyContent:
                alignItems === "center" ? "space-around" : "flex-end",
            }}
          >
            {callouts.map((item, index) => (
              <Button
                key={index}
                className={item.color}
                size="medium"
                onClick={() =>
                  item.link && (window.location.href = item.link.href)
                }
              >
                {item.text}
              </Button>
            ))}
          </div>
        )}
      </div>
      {gallery && (
        <ul className="gallery">
          {gallery.map((item, index) => (
            <li key={index}>
              <ContentImage
                title={item.title}
                image={item.image}
                link={item.link}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : (
    <></>
  )
}

export default Content
