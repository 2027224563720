import classNames from "classnames"
import React, { FunctionComponent, useState } from "react"
import { isMobileOnly, isTablet } from "react-device-detect"
import { ICarouselItem } from "../types"
import "./ImageGallery.scss"
import SimpleModal from "./SimpleModal"

export const ImageGallery: FunctionComponent<{
  items: ICarouselItem[]
}> = ({ items }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [imageSrc, setImageSrc] = useState<string>("")

  const handleOpenModal = (src: string): void => {
    setShowModal(true)
    setImageSrc(src)
  }
  return (
    <div
      className={classNames("image-gallery", {
        mobile: isMobileOnly,
        tablet: isTablet,
      })}
    >
      {items.map((item, index) => (
        <div className="item" key={index}>
          <img
            className="image"
            src={item.localFile.childImageSharp.fluid.src}
            onClick={() => {
              handleOpenModal(item.localFile.childImageSharp.fluid.src)
            }}
          />
        </div>
      ))}

      <SimpleModal
        open={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        containerStyle={{
          background: "none",
          maxWidth: "80rem",
          width: "auto",
        }}
        buttonStyle={{
          color: "var(--color-white)",
          position: "relative",
          left: "0.5rem",
        }}
      >
        <img src={imageSrc} />
      </SimpleModal>
    </div>
  )
}

export default ImageGallery
