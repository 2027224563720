import React from "react"
import PropType from "prop-types"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { CssGrid } from "../components/CssGrid"
import { Hero } from "../components/Hero"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { Content } from "../components/Content"
import { Subnavigation } from "../components/Subnavigation"
import { ImageGallery } from "../components/ImageGallery"
import { graphql } from "gatsby"
import "./MediaCentre.scss"

let SUBMENU = []
let IMAGE_GALLERY = []

const PostTemplate = props => {
  const {
    data: { wordpressWpGallery: post, submenu },
  } = props
  return (
    <Layout>
      <CssGrid className="internal-page media-centre">
        <article>
          <Hero>
            <Content
              fillBackgroundWithImage
              fillBlockWithImage
              backgroundImage="about_ovolo_banner.jpg"
            >
              <h1>Image Gallery</h1>
            </Content>
          </Hero>
          {submenu.edges.map(({ node }, i) => {
            SUBMENU = node.items
          })}
          <Subnavigation items={SUBMENU} />
          <Breadcrumbs categoryName="Galleries" />
          <div className="container">
            <div className="row">
              <CssGrid>
                <div className="col col-xs-12 -col-md-3">Related Galleries</div>
                <div className="col col-xs-12 col-md-9 gallery">
                  <Content>
                    <div className="categories">
                      {post.categories &&
                        post.categories.map(category => `${category.name}, `)}
                    </div>
                    <h2>{post.title}</h2>
                  </Content>
                  {post.acf.gallery_images.map(({ image }) => {
                    IMAGE_GALLERY.push(image)
                  })}
                  <ImageGallery items={IMAGE_GALLERY} />
                </div>
              </CssGrid>
            </div>
          </div>
        </article>
      </CssGrid>
    </Layout>
  )
}
PostTemplate.propTypes = {
  data: PropType.shape({}).isRequired,
}
export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpGallery(id: { eq: $id }) {
      title
      date(formatString: "DD, MMM YYYY")
      slug
      acf {
        gallery_images {
          image {
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 512) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    submenu: allWordpressWpApiMenusMenusItems(
      filter: { slug: { eq: "media" } }
    ) {
      edges {
        node {
          items {
            title
            url
            target
          }
        }
      }
    }
  }
`
