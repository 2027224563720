import React, { FunctionComponent } from "react"
import { ISubNavigationItem } from "../types"

import "./Subnavigation.scss"
import classNames from "classnames"
import { window } from "browser-monads"

let Location
const hasWindow = typeof window.location !== `undefined` ? true : null
if (hasWindow) {
  Location = window.location.pathname
}

export const Subnavigation: FunctionComponent<{
  items: ISubNavigationItem[]
}> = ({ items }) => (
  <nav className={classNames("subnavigation dynamic")}>
    <a
      className="subnavigation-mobile"
      onClick={event => {
        event.preventDefault()

        //Trust it's there
        event.target
          .closest(".subnavigation")
          .classList.toggle("mobile-visible")

        return false
      }}
    >
      <span className="subnavigation-mobile--burger">
        <span></span>
        <span></span>
        <span></span>
      </span>
      <span>View List</span>
    </a>
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <a
            className={classNames({
              active: item.url == Location ? "active" : null,
            })}
            href={item.url}
          >
            {item.title}
          </a>
        </li>
      ))}
    </ul>
  </nav>
)

export default Subnavigation
