import React, { FunctionComponent } from "react"

import "./Breadcrumbs.scss"
import classNames from "classnames"
import { isMobileOnly } from "react-device-detect"

export const Breadcrumbs: FunctionComponent<{
  categoryName: string
  categorySlug?: string
  pageName?: string
}> = ({ categoryName, categorySlug, pageName }) => (
  <div
    className={classNames("breadcrumbs", {
      mobile: isMobileOnly,
    })}
  >
    <a href="/media/">Media Centre</a> /
    {!pageName && <strong>{categoryName}</strong>}
    {pageName && (
      <div>
        <a href={categorySlug}>{categoryName}</a> / <strong>{pageName}</strong>
      </div>
    )}
  </div>
)
